import React from 'react';

const SvgDiscord = (props: Props): JSX.Element => {
	return (
		<svg
			width={22}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.725 10.488c-.717 0-1.283.625-1.283 1.387s.579 1.387 1.283 1.387c.716 0 1.282-.625 1.282-1.387.013-.762-.566-1.387-1.282-1.387zm4.588 0c-.716 0-1.282.625-1.282 1.387s.578 1.387 1.282 1.387c.717 0 1.282-.625 1.282-1.387s-.565-1.387-1.282-1.387z"
				fill={props.fill ? props.fill : '#fff'}
			/>
			<path
				d="M19.423 0H2.577A2.576 2.576 0 000 2.575v16.9a2.576 2.576 0 002.577 2.575h14.256l-.666-2.313 1.609 1.488 1.521 1.4L22 25V2.575A2.576 2.576 0 0019.423 0zM14.57 16.325s-.452-.537-.83-1.012c1.647-.463 2.276-1.488 2.276-1.488a7.209 7.209 0 01-1.446.738 8.31 8.31 0 01-1.823.537 8.856 8.856 0 01-3.256-.012 10.599 10.599 0 01-1.848-.538 7.387 7.387 0 01-.917-.425c-.038-.025-.076-.037-.113-.063-.026-.012-.038-.024-.05-.037-.227-.125-.353-.213-.353-.213s.604 1 2.2 1.476c-.377.474-.842 1.037-.842 1.037-2.778-.087-3.834-1.9-3.834-1.9 0-4.025 1.81-7.288 1.81-7.288 1.81-1.35 3.533-1.312 3.533-1.312l.125.15c-2.263.65-3.306 1.638-3.306 1.638s.277-.15.742-.363c1.345-.588 2.413-.75 2.853-.787.076-.013.139-.026.214-.026a10.29 10.29 0 016.323 1.175s-.993-.937-3.13-1.587l.176-.2s1.723-.038 3.533 1.313c0 0 1.81 3.262 1.81 7.287 0 0-1.068 1.813-3.847 1.9z"
				fill={props.fill ? props.fill : '#fff'}
			/>
		</svg>
	);
};

type Props = {} & React.SVGProps<SVGSVGElement>;
export default SvgDiscord;
