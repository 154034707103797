import React from 'react';

const SvgGithub = (props: Props): JSX.Element => {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g
				clipPath="url(#github_svg__clip0)"
				fill={props.fill ? props.fill : '#fff'}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 0C5.373 0 0 5.51 0 12.305 0 17.74 3.438 22.354 8.207 23.98c.6.114.82-.267.82-.593 0-.294-.012-1.264-.016-2.29-3.34.743-4.043-1.451-4.043-1.451-.547-1.422-1.332-1.801-1.332-1.801-1.089-.764.082-.748.082-.748 1.204.087 1.84 1.267 1.84 1.267 1.07 1.88 2.808 1.337 3.491 1.023.107-.795.42-1.339.761-1.646-2.664-.312-5.466-1.367-5.466-6.08 0-1.343.469-2.441 1.236-3.303-.125-.31-.535-1.56.115-3.256 0 0 1.007-.33 3.3 1.261.958-.273 1.984-.41 3.005-.413 1.02.004 2.047.14 3.007.413 2.29-1.591 3.296-1.261 3.296-1.261.654 1.695.242 2.946.117 3.256.77.862 1.234 1.96 1.234 3.303 0 4.725-2.808 5.766-5.478 6.072.431.38.815 1.13.815 2.277 0 1.647-.014 2.971-.014 3.377 0 .328.216.71.824.59C20.566 22.35 24 17.74 24 12.306 24 5.509 18.627 0 12 0z"
				/>
				<path d="M4.545 17.665c-.026.062-.122.08-.207.037-.088-.04-.135-.124-.107-.185.025-.064.119-.08.206-.04.088.044.137.127.108.188zM5.031 18.222c-.057.055-.168.028-.244-.057-.08-.086-.093-.2-.036-.255.06-.055.167-.029.247.057.077.085.093.2.033.255zM5.504 18.93c-.073.054-.194.005-.268-.105-.073-.11-.073-.24.002-.294.074-.053.193-.006.268.104.074.11.074.243-.002.296zM6.152 19.615c-.066.076-.207.055-.308-.047-.105-.1-.133-.24-.068-.314.068-.075.207-.052.31.047.104.098.136.24.066.314zM7.046 20.012c-.03.096-.165.141-.3.1-.135-.042-.225-.154-.197-.252.028-.098.163-.143.3-.1.137.043.225.155.197.252zM8.028 20.086c.004.102-.111.185-.255.187-.143.004-.26-.08-.26-.179 0-.102.113-.185.257-.188.14-.002.258.08.258.18zM8.942 19.927c.018.1-.082.2-.225.228-.139.027-.268-.034-.286-.132-.018-.102.084-.204.223-.23.143-.027.27.034.288.134z" />
			</g>
			<defs>
				<clipPath id="github_svg__clip0">
					<path fill={props.fill ? props.fill : '#fff'} d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

type Props = { fill?: string } & React.SVGProps<SVGSVGElement>;
export default SvgGithub;
